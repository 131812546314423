import React from "react";
import Header from "../sections/Header/Header";

const Layout = ({ children, onScrollTo }) => {
  return (
    <>
      <Header onScrollTo={onScrollTo} />
      <main className="leading-8">{children}</main>
      <footer>
        <div className="bg-black-400 text-white text-center py-5">
          <p>© 2024 All Rights Reserved</p>
        </div>
      </footer>
    </>
  );
};

export default Layout;
