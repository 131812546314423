import React from "react";
import DesktopNav from "./DesktopNav";
import MobileNav from "./MobileNav";

const Header = ({ onScrollTo }) => {
  return (
    <header className="bg-red flex px-5 justify-between text-white items-center fixed w-full top-0 left-0">
      <div className="flex-1">
        <img alt="logo" src="/images/logo.png" width="60px" />
      </div>
      <DesktopNav onScrollTo={onScrollTo} />
      <MobileNav onScrollTo={onScrollTo} />
      <div className="flex-1 hidden lg:block"></div>
    </header>
  );
};

export default Header;
