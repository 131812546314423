export const navLinks = [
  // {
  //   title: "Home",
  //   path: "/",
  // },
  {
    title: "About",
    path: "about",
  },
  {
    title: "Services",
    path: "services",
  },
  {
    title: "Contact",
    path: "contact",
  },
];
