import React from "react";
import DecorationRow from "./DecorationRow";
import classNames from "classnames";

const SectionHeader = ({ title, color, rowClassName }) => {
  return (
    <div className="flex flex-col items-center gap-3">
      <h3
        className={classNames({
          "text-3xl font-bold uppercase": true,
          [`text-${color}`]: color,
        })}
      >
        {title}
      </h3>
      <DecorationRow
        className={classNames({
          "w-[150px]": true,
          [rowClassName]: !!rowClassName,
        })}
      />
    </div>
  );
};

export default SectionHeader;
