import React, { useState } from "react";
import { navLinks } from "../../utils/nav";
import { navigate } from "gatsby";

const MobileNav = ({ onScrollTo }) => {
  const [isOpen, setIsOpen] = useState(false);

  const scrollToHandler = async (path) => {
    if (onScrollTo) {
      onScrollTo(path);
    } else {
      await navigate(`/#${path}`);
    }
  };

  return (
    <div className="lg:hidden transition-all">
      <div className="py-2">
        <span
          className="material-symbols-outlined text-white text-4xl"
          onClick={() => setIsOpen((prevState) => !prevState)}
        >
          {!isOpen ? "menu" : "close"}
        </span>
      </div>
      {isOpen && (
        <ul className="absolute top-15 left-0 bg-black-100 w-full">
          {navLinks.map((item) => (
            <li
              key={item.title}
              className="py-4 px-4 text-white uppercase text-center cursor-pointer hover:bg-black-200 transition-all"
              onClick={() => scrollToHandler(item.path)}
            >
              {item.title}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default MobileNav;
