import React from "react";
import { navLinks } from "../../utils/nav";
import { Link, navigate } from "gatsby";

const DesktopNav = ({ onScrollTo }) => {
  const scrollToHandler = async (path) => {
    if (onScrollTo) {
      onScrollTo(path);
    } else {
      await navigate(`/#${path}`);
    }
  };

  return (
    <nav className="hidden lg:block">
      <ul className="flex">
        <li className="py-4 px-4">
          <Link
            to="/"
            className="py-4 uppercase font-black hover:border-b-4 hover:border-white"
          >
            Home
          </Link>
        </li>
        {navLinks.map((item) => (
          <li key={item.title} className="py-4 px-4">
            <div
              className="py-4 uppercase font-black hover:border-b-4 hover:border-white cursor-pointer inline"
              onClick={() => scrollToHandler(item.path)}
            >
              {item.title}
            </div>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default DesktopNav;
