import React from "react";
import classNames from "classnames";

const DecorationRow = ({ className }) => {
  return (
    <hr
      className={classNames({
        "block w-[100px] bg-red h-1 md:h-1.5 border-0": true,
        [className]: !!className,
      })}
    />
  );
};

export default DecorationRow;
