import React from "react";
import SectionHeader from "./SectionHeader";
import Layout from "./Layout";
import { Link } from "gatsby";

const SingleServicePageLayout = ({ children, serviceName }) => {
  return (
    <Layout>
      <div className="py-20 bg-black-200 min-h-screen px-5 md:px-20 text-white">
        <div className="text-gray-400">
          <Link to="/" className="underline hover:text-white">
            Home
          </Link>{" "}
          / Services / {serviceName}
        </div>
        <div className="mt-10">
          <SectionHeader color="white" title={serviceName} />
        </div>
        <div className="mt-10 flex flex-col gap-5 md:px-[10%]">{children}</div>
      </div>
    </Layout>
  );
};

export default SingleServicePageLayout;
